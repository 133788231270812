import { Button, Grid, Stack } from '@mui/material'
import React from 'react'
import '../styles/ContactPage.scss'

export const ContactPage = () => {
    return (
        <Grid className='Home ContactPage' container justifyContent={'space-between'}
            alignContent={'center'} alignItems={'center'} sx={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <h1>Contact</h1>
            </Grid>
            <Grid item xs={12} className='contactRow'>
                <a href="mailto:emylee@esteban-inc.com" title='Esteban Inc Email'>
                    <Stack className='contactStack' direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <h2 className='contactTitle'>
                            EMAIL
                        </h2>
                        <h3 className='contactTitle'>
                            emylee@esteban-inc.com
                        </h3>
                        <h2 className='hoverContact'>
                            &#128075; Say Hello &#128075;
                        </h2>
                        <Button variant='outlined' className='outlinedBtn'>
                            &#128075; Say Hello &#128075;
                        </Button>
                    </Stack>
                </a>

            </Grid>
            <Grid item xs={12} className='contactRow'>
                <a target="_blank" href='https://estebaninc.etsy.com' title='Esteban Inc Etsy'>
                    <Stack className='contactStack' direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <h2 className='contactTitle'>
                            ETSY
                        </h2>
                        <h3 className='contactTitle'>
                            estebaninc
                        </h3>
                        <h2 className='hoverContact'>
                            &#x1F58C;&#xFE0F; Explore My Creations &#x1F58C;&#xFE0F;
                        </h2>
                        <Button variant='outlined' className='outlinedBtn'>
                            &#x1F58C;&#xFE0F; Explore My Creations &#x1F58C;&#xFE0F;
                        </Button>
                    </Stack></a>
            </Grid>
            <Grid item xs={12} className='contactRow'>
                <a target="_blank" href='https://www.instagram.com/esteban.inc/' title='Esteban Inc Instagram'>
                    <Stack className='contactStack' direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <h2 className='contactTitle'>
                            INSTAGRAM
                        </h2>
                        <h3 className='contactTitle'>
                            esteban.inc
                        </h3>
                        <h2 className='hoverContact'>
                            &#128247; Follow My Journey &#128247;
                        </h2>
                        <Button variant='outlined' className='outlinedBtn'>
                            &#128247; Follow My Journey &#128247;
                        </Button>
                    </Stack>
                </a>
            </Grid>
            <Grid item xs={12} className='contactRow'>
                <a target="_blank" href='https://www.tiktok.com/@esteban_inc' title='Esteban Inc Tik Tok'>
                    <Stack className='contactStack' direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <h2 className='contactTitle'>
                            TIK TOK
                        </h2>
                        <h3 className='contactTitle'>
                            esteban_inc
                        </h3>
                        <h2 className='hoverContact'>
                            &#127909;&#65039; Peek Behind the Scenes &#127909;&#65039;
                        </h2>
                        <Button variant='outlined' className='outlinedBtn'>
                            &#127909;&#65039; Peek Behind the Scenes &#127909;&#65039;
                        </Button>
                    </Stack>
                </a>
            </Grid>
            <Grid item xs={12} className='contactRow'>
                <a target="_blank" href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn'>
                    <Stack className='contactStack' direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <h2 className='contactTitle'>
                            LINKEDIN
                        </h2>
                        <h3 className='contactTitle'>
                            emyleeesteban
                        </h3>
                        <h2 className='hoverContact'>
                            &#128188; Connect with Me &#128188;
                        </h2>
                        <Button variant='outlined' className='outlinedBtn'>
                            &#128188; Connect with Me &#128188;
                        </Button>
                    </Stack>
                </a>
            </Grid>
            <Grid item xs={12}>
                <iframe
                    className='jotFormFrame'
                    loading='lazy'
                    id="JotFormIFrame-241797387833069"
                    title="Esteban Inc. Contact Form"
                    allow="geolocation; microphone; camera; fullscreen"
                    src="https://form.jotform.com/241797387833069"
                    frameBorder={0}
                    allowtransparency="true"
                    scrolling='no'
                    style={{
                        "minWidth": '100%',
                        'maxWidth': '100%',
                        'height': '700px',
                        'border': 'none',
                        'minHeight': '700px'
                    }}
                >
                </iframe>
            </Grid>
        </Grid>
    )
}