import React from 'react'
import { resumeJobs } from '../projects'
import { Grid, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import '../styles/Resume.scss'

export const Resume = () => {
    return (
        <div className='Resume'>
            <header className="header" >

                <div>
                    <h1>
                        Emylee Esteban

                    </h1>

                </div>

            </header>
            <div className='container'>
                <div>
                    Virginia Tech, BFA Visual Communication Design
                </div>

                <div className='jobList'>
                    {
                        resumeJobs.map(job => {
                            return (
                                <div className='jobListItem'
                                    key={'job_' + job.id}>
                                    <Grid container
                                        spacing={2}
                                        sx={{
                                            width: '100%'
                                        }}>
                                        <Grid item lg={6}>
                                            <Stack>

                                                <div className='title'>
                                                    {
                                                        job.company
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        job.title
                                                    }
                                                </div>
                                                <div className='dates'>
                                                    {
                                                        job.dates
                                                    }
                                                </div>

                                            </Stack>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <ul id='jobDescList'>
                                                {
                                                    job.list.map(item => {
                                                        return (
                                                            <li key={item.listID + '_item'}>
                                                                {item.listDescription}
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <footer>
                <Grid container className='links' spacing={2} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link to="/">Portfolio</Link>
                    </Grid>
                    <Grid item>
                        <a href="https://www.linkedin.com/in/emyleeesteban" target='_blank' title="Esteban Inc. LinkedIn">Linkedin</a>
                    </Grid>
                </Grid>
            </footer>
        </div>
    )
}